<template>
  <v-main>
    <v-container fluid >
      <v-flex xs12 sm6 offset-sm3 mt-13 >
        <p class="font-weight-bold text-center">
          Ваша учетная запись успешно активирована!
        </p>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  data () {
    return {
      token: this.$route.params.token
    }
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push("/home")
    }
    this.checkToken()
    this.interval = setInterval(() => this.$router.push("/login"), 5000);
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    checkToken() {
      axios.get(process.env.VUE_APP_API_URL + '/api/v1/account/active/' + this.token + '/')
        .then()
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch('logout');
          } else {
            this.$router.push("/error")
          }
        });
    }
  }
};
</script>

